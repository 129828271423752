import {
    handleQueryResolve
} from '../utils'

export default function(patientID, rxNumber, genericName, brandName, mixName) {
    if (patientID) {
        const attrs = [{
            param: 'PatientID',
            type: 'sql.Int',
            value: patientID, // to support like
        }]
        const where = ['Rx.PatientID = @PatientID']

        if (rxNumber) {
            attrs.push({
                param: 'rxNumber',
                type: 'sql.VarChar(50)',
                value: `%${rxNumber}%`, // to support like
            })

            where.push('Rx.RXNumber LIKE @rxNumber')
        }

        if (genericName) {
            attrs.push({
                param: 'genericName',
                type: 'sql.VarChar(50)',
                value: `%${genericName}%`, // to support like
            })

            where.push('DrugRoot.GenericName LIKE @genericName')
        }

        if (brandName) {
            attrs.push({
                param: 'brandName',
                type: 'sql.VarChar(50)',
                value: `%${brandName}%`, // to support like
            })

            where.push('DrugRoot.BrandName LIKE @brandName')
        }

        if (mixName) {
            attrs.push({
                param: 'mixName',
                type: 'sql.VarChar(50)',
                value: `%${mixName}%`, // to support like
            })

            where.push('MixHeader.MixName LIKE @mixName')
        }

        return this.query(`
    SELECT top 1 with ties
    Rx.RXNumber as RxNumber,
    Rx.DoctorID,
    Doctor.LastName,
    Doctor.FirstName,
    doctor.ID as CPSO,
    DoctorPhone.Phone as DoctorPhone,
    DoctorFax.Phone as DoctorFax,
    Rx.RxDate,
    Rx.DIN,
    DrugRoot.GenericName as GenericName,
    DrugRoot.Strength AS RxStrength,
    DrugRoot.Form,
    DrugRoot.Schedule,
    DrugRoot.BrandName as BrandName,
    Rx.QtyDispense AS RxQtyDispense,
    Rx.QtyRemain,
    Rx.SIGFull,
    Rx.QtyDispense,
    Indication.Description,
    Rx.PatientID,
    Rx.Days,
    MixHeader.MixNumber,
    MixHeader.MixName as MixName,
    rx.hold,
    (
        CASE
        WHEN (
        (Rx.Status is null)
        ) THEN 'COM'
        ELSE Rx.Status
        END
        ) as Status,
    Rx.QtyAuthorize as TotalAuthorized,
    DosR.Brk,
    DosR.Noon,
    DosR.Supp,
    DosR.Bed,
    DosR.IsOTC,
    DosR.IsInactive,
    DosR.ModifiedOn,
    DosR.Modification,
    DosR.PrevRxNumber,
    CEILING((Rx.QtyDispense /Rx.Days)*180) as sixMonth,
    CEILING((Rx.QtyDispense /Rx.Days)*30) as oneMonth,
    ROUND((Rx.QtyDispense /Rx.Days),1) as oneDay,
    RxH.RxNotes,
    RxH.CounselReason
FROM
    Fillware.dbo.Rx
    LEFT JOIN Fillware.dbo.DrugRoot ON Fillware.dbo.Rx.DIN = Fillware.dbo.DrugRoot.DIN
    LEFT JOIN Fillware.dbo.MixHeader ON Fillware.dbo.Rx.MixNumber = Fillware.dbo.MixHeader.MixNumber
    LEFT JOIN Fillware.dbo.RxHardCopy as RxH ON Fillware.dbo.Rx.RxNumber = RxH.RxNumber
    LEFT JOIN Fillware.dbo.Doctor ON Fillware.dbo.Rx.DoctorID = Fillware.dbo.Doctor.DoctorID
    INNER JOIN
    Fillware.dbo.DoctorAddress as DoctorAddress ON doctor.DoctorID = DoctorAddress.DoctorID AND DoctorAddress.DefaultAddress = 1
    INNER JOIN
    Fillware.dbo.RxExtended as RxExtended on RxExtended.RxNumber = Rx.RxNumber
    LEFT JOIN
    Fillware.dbo.DoctorPhone as DoctorFax ON DoctorFax.AddressID = RxExtended.DoctorAddressID AND DoctorFax.PhoneType = 'FAX'
    LEFT JOIN
    Fillware.dbo.DoctorPhone as DoctorPhone ON DoctorPhone.AddressID = RxExtended.DoctorAddressID AND DoctorPhone.PhoneType = 'BUSINESS'
    LEFT JOIN
    Fillware.dbo.DosR ON DosR.RxNumber = Rx.RxNumber
    LEFT JOIN
    Fillware.dbo.DrugCategories as Indication ON DrugRoot.Therapeutic = Indication.Code
WHERE
    Rx.RxDate > DATEADD(month, -13, GetDate())
    AND
    Rx.PatientID = @PatientID
    AND (  rx.status = 'T' or rx.status = 'I' or rx.status = 'F' or rx.status = 'NULL' or rx.status is null) 
    --and rx.hold = 0
    -- Rx.PatientID = 200
ORDER BY
    row_number() over (partition by DrugRoot.GenericName, DrugRoot.Strength, rx.hold order by Rx.RxDate DESC)
        `, attrs).then(handleQueryResolve)
    }
}

// SELECT
// Rx.RXNumber as RxNumber,
//     Rx.RxDate,
//     Rx.DIN,
//     DrugRoot.GenericName as GenericName,
//     DrugRoot.Strength AS RxStrength,
//         DrugRoot.BrandName as BrandName,
//         Rx.QtyDispense AS RxQtyDispense,
//             Rx.QtyRemain,
//             Rx.SIGFull,
//             Rx.QtyDispense,
//             Rx.PatientID
// FROM
// Fillware.dbo.Rx
// INNER JOIN
// Fillware.dbo.DrugRoot ON Fillware.dbo.Rx.DIN = Fillware.dbo.DrugRoot.DIN
// WHERE
// Rx.RxDate > DATEADD(month, -12, GetDate()) AND(Rx.Status <> 'D' OR Rx.Status <> 'I' OR Rx.Status is null)
// AND
// ${ where.join(' AND ') }
// ORDER BY
// Rx.RxDate Desc